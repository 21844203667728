import { Injectable } from "@angular/core";
import {interval, Observable} from "rxjs";
import { isIosWebView } from "./util";
import { UpdateUserGeoLocationRequest } from "../model/requests";
import { UserService } from "./api/user.service";
import {SwUpdate} from "@angular/service-worker";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmDialogComponent} from "../shared/confirm-dialog.component";

@Injectable()
export class AppUpdateService {
    private disabled = false;

    constructor(
        private swUpdate: SwUpdate,
        private dialog: MatDialog,
    ) {
        interval(2 * 60 * 1000).subscribe(() => {
            this.checkForUpdates();
        });
    }

    checkForUpdates() {
        if (this.disabled || !this.swUpdate.isEnabled) {
            console.log('Updates disabled');
            return;
        }
        console.log('Checking for updates');
        try {
            this.swUpdate.checkForUpdate().then(available => {
                if (available) {
                    console.log('New version available');
                    this.dialog.open(ConfirmDialogComponent, {
                        data: {
                            title: $localize`New version available`,
                            question: $localize`A new version of the app is available. Reload now?`,
                            confirm: $localize`Reload`,
                            cancel: $localize`Later`,
                        }
                    }).afterClosed().subscribe(result => {
                        if (!result) {
                            this.disabled = true;
                            return;
                        }
                        try {
                            this.swUpdate.activateUpdate().then(() => {
                                console.log('New version activated');
                                window.location.reload();
                            });
                        } catch (e) {
                            console.error('Error activating update', e);
                        }
                    })
                } else {
                    console.log('No new version available');
                }
            })
        } catch (e) {
            console.error('Error checking for updates', e);
        }
    }
}
