import {Routes} from "@angular/router";

// Some of these routes only exists to have direct access to the component, at some point this should be cleaned up
export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./app/home/home.component').then(m => m.HomeComponent)
    },
    {
        path: 'search',
        loadComponent: () => import('./app/search/search.component').then(m => m.SearchComponent)
    },
    {
        path: 'notifications',
        loadComponent: () => import('./app/notifications/notifications.component').then(m => m.NotificationsComponent)
    },

    // Messages
    {
        path: 'messages',
        loadComponent: () => import('./app/messages/messages.component').then(m => m.MessagesComponent)
    },
    {
        path: 'messages/new',
        loadComponent: () => import('./app/messages/new-message.component').then(m => m.NewMessageComponent)
    },
    {
        path: 'messages/new/:username',
        loadComponent: () => import('./app/messages/conversation.component').then(m => m.ConversationComponent)
    },
    {
        path: 'messages/:conversationId',
        loadComponent: () => import('./app/messages/conversation.component').then(m => m.ConversationComponent)
    },

    // Meetups
    {
        path: 'meetups',
        loadComponent: () => import('./app/meetups/meetups.component').then(m => m.MeetupsComponent)
    },
    {
        path: 'meetups/:meetupId',
        loadComponent: () => import('./app/meetups/meetup.component').then(m => m.MeetupComponent)
    },
    {
        path: 'tables/:tableId',
        loadComponent: () => import('./app/meetups/planned-play.component').then(m => m.PlannedPlayComponent)
    },

    // Groups
    {
        path: 'groups',
        loadComponent: () => import('./app/groups/groups.component').then(m => m.GroupsComponent)
    },
    {
        path: 'groups/:groupId',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },
    {
        path: 'groups/:groupId/activity',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },
    {
        path: 'groups/:groupId/stats',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },
    {
        path: 'groups/:groupId/games',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },
    {
        path: 'groups/:groupId/games/:ownership',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },
    {
        path: 'groups/:groupId/plays',
        loadComponent: () => import('./app/groups/group.component').then(m => m.GroupComponent)
    },

    // Auth
    {
        path: 'auth',
        loadComponent: () => import('./app/auth/login-view.component').then(m => m.LoginViewComponent)
    },
    {
        path: 'auth/register',
        loadComponent: () => import('./app/auth/register-view.component').then(m => m.RegisterViewComponent)
    },
    {
        path: 'auth/forgot-password',
        loadComponent: () => import('./app/auth/forgot-password.component').then(m => m.ForgotPasswordComponent)
    },
    {
        path: 'auth/reset-password',
        loadComponent: () => import('./app/auth/reset-password.component').then(m => m.ResetPasswordComponent)
    },
    {
        path: 'login',
        loadComponent: () => import('./app/auth/login-view.component').then(m => m.LoginViewComponent)
    },
    {
        path: 'login/register',
        loadComponent: () => import('./app/auth/register-view.component').then(m => m.RegisterViewComponent)
    },
    {
        path: 'login/forgot-password',
        loadComponent: () => import('./app/auth/forgot-password.component').then(m => m.ForgotPasswordComponent)
    },
    {
        path: 'login/reset-password',
        loadComponent: () => import('./app/auth/reset-password.component').then(m => m.ResetPasswordComponent)
    },

    // Settings
    {
        path: 'settings',
        loadComponent: () => import('./app/settings/settings.component').then(m => m.SettingsComponent)
    },
    {
        path: 'settings/account-info',
        loadComponent: () => import('./app/settings/account-info.component').then(m => m.AccountInfoComponent)
    },
    {
        path: 'settings/change-password',
        loadComponent: () => import('./app/settings/change-password.component').then(m => m.ChangePasswordComponent)
    },
    {
        path: 'settings/bgg-integration',
        loadComponent: () => import('./app/settings/bgg-integration.component').then(m => m.BggIntegrationComponent)
    },
    {
        path: 'settings/speed-add',
        loadComponent: () => import('./app/settings/speed-add-view.component').then(m => m.SpeedAddViewComponent)
    },
    {
        path: 'settings/notifications',
        loadComponent: () => import('./app/settings/notification-settings.component').then(m => m.NotificationSettingsComponent)
    },
    {
        path: 'settings/privacy',
        loadComponent: () => import('./app/settings/privacy-settings.component').then(m => m.PrivacySettingsComponent)
    },
    {
        path: 'settings/players',
        loadComponent: () => import('./app/settings/user-player-settings.component').then(m => m.UserPlayerSettingsComponent)
    },

    // User
    {
        path: ':username',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/activity',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/stats',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/owned',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/previously_owned',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/preordered',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/wishlisted',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/played',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/collection/:tag',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/plays',
        loadComponent: () => import('./app/user/user.component').then(m => m.UserComponent)
    },
    {
        path: ':username/reviews',
        loadComponent: () => import('./app/user/user-reviews.component').then(m => m.UserReviewsComponent)
    },
    {
        path: 'player/:playerId',
        loadComponent: () => import('./app/user/player/user-player-page.component').then(m => m.UserPlayerPageComponent)
    },

    // Game
    {
        path: 'g/:gameSlug',
        loadComponent: () => import('./app/game/game.component').then(m => m.GameComponent)
    },
    {
        path: 'g/:gameSlug/community',
        loadComponent: () => import('./app/game/game.component').then(m => m.GameComponent)
    },
    {
        path: 'g/:gameSlug/my-stats',
        loadComponent: () => import('./app/game/game.component').then(m => m.GameComponent)
    },
    {
        path: 'g/:gameSlug/reviews',
        loadComponent: () => import('./app/game/game.component').then(m => m.GameComponent)
    },
    {
        path: 'g/:gameSlug/prices',
        loadComponent: () => import('./app/game/game.component').then(m => m.GameComponent)
    },
    {
        path: 'g/:gameSlug/helpers',
        loadComponent: () => import('./app/game/game-helper-list-view.component').then(m => m.GameHelperListViewComponent)
    },

    // Play
    {
        path: 'p/:playId',
        loadComponent: () => import('./app/play/play.component').then(m => m.PlayComponent)
    },

    // Post
    {
        path: 'post/:postId',
        loadComponent: () => import('./app/post/post-view.component').then(m => m.PostViewComponent)
    },

    // Review
    {
        path: 'review/:reviewId',
        loadComponent: () => import('./app/game/review/review-view.component').then(m => m.ReviewViewComponent)
    },

    // Wildcard
    {
        path: '**',
        redirectTo: ''
    }
];
